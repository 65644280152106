import API from "./API";

const authenticate = async ({ mobile }) => {
  const response = await API.post('/api/auth/login/authenticate', { mobile });
  return response.data;
};

const validate = async ({ mobile, secret }) => {
  const response = await API.post('/api/auth/login/validate', { mobile, secret });
  const token = response.headers['access-token'];
  return { data: response.data, token };
};

export default { authenticate, validate };
