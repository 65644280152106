import React from 'react'
import PropTypes from 'prop-types';
import Navigation from './Navigation'
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Base = (props) => {

  const location = useLocation();

  const { isAuthenticated } = useSelector(state => state.auth);

  const shouldShowFooter = !isAuthenticated && location.pathname === '/';

  return (
    <div id="car-quiz" className="">
      <Navigation />
      {props.children}
      {shouldShowFooter && <Footer />}
    </div>
  )
}

Base.propTypes = {
  children: PropTypes.object
}
export default Base