import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import quizReducer from './features/quizSlice';
import sharedReducer from './features/sharedSlice';
import cloudReducer from './features/cloudSlice';
import userReducer from './features/userSlice';
import { getState, saveState } from './utils';

const data = getState();

const store = configureStore({
  reducer: {
    auth: authReducer,
    quiz: quizReducer,
    shared: sharedReducer,
    cloud: cloudReducer,
    user: userReducer
  },
  preloadedState: data
});

export const unsubscribe = store.subscribe(() => {
  saveState(store.getState());
});

export default store;
