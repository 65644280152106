import React from "react";
export const Banner = () => {
  return (
    <>
      <div className="kidex-regis-nrc-steps-banner position-relative" id="">
        <div className="coding-txt nunito">Coding</div>
        <div className="container">
          <h2 className="fs-18 montserrat text-white lh-1">
            <span className="d-block">Ready, Set, Go!</span>
            <span className="d-flex align-items-md-end">Boost your learning journey with <b className="d-flex ms-1 align-items-md-end color-banner-blue"> 3 easy steps! <img src="../images/register-nrc/rocket-white.png" className="ms-1" /></b></span>
          </h2>
        </div>
        <div className="container banner-container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-4 col-6  text-center">
              <img src="../images/register-nrc/reg-step1.svg" className="mw-100"/>
              <h2 className="fs-20 montserrat color-light-violet lh-sm">
                Step 1
                <b className="d-block pt-1 pt-md-0 text-white disable-line-break-comp">Project <br /> Details</b>
              </h2>
            </div>
            <div className="col-lg-4 col-md-4 col-6  text-center img-arrow">
              <img src="../images/register-nrc/reg-step2.svg"  className="mw-100"/>
              <h2 className="fs-20 montserrat color-light-violet lh-sm">
                Step 2
                <b className="d-block  pt-1 pt-md-0 text-white disable-line-break-comp">Video <br /> Tutorials</b>
              </h2>
            </div>
            <div className="col-lg-4 col-md-4 col-6  text-center">
              <img src="../images/register-nrc/reg-step3.svg"  className="mw-100"/>
              <h2 className="fs-20 montserrat color-light-violet lh-sm">
                Step 3
                <b className="d-block  pt-1 pt-md-0 text-white disable-line-break-comp">Submit <br /> Performance Video</b>
              </h2>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};
