import React from 'react'

const AnswerCard = ({ questionData, quizLocalData, handleAnswer, answer, isClickable }) => {
  const isSelected = answer.indexOf(questionData?.option_id) >= 0;
  const isIncorrect = quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && answer.indexOf(questionData?.option_id) >= 0 && 'selected-incorrect'
  const isCorrect = (quizLocalData?.correct_options != null && quizLocalData?.correct_options == true && answer.indexOf(questionData?.option_id) >= 0 || quizLocalData?.answer_description?.option_ids?.indexOf(questionData?.option_id) >= 0) && 'selected-correct'
  return (
    <div
      className={`card cstm_card ${isClickable && "cursor-pointer"} mb-4 ${isSelected ? "active" : ""} ${isIncorrect ?? ""} ${isCorrect ?? ""}`}
      onClick={isClickable ? () => handleAnswer(questionData?.option_id, true) : null}
    >
      <div className={`text-grey mb-0 text-bold h5 ${questionData?.image ? 'd-flex' : ''}`}>{questionData?.option_text}
        {questionData?.image && (
          <div className='ms-auto'>
            <img src={`${window.location.origin}/images/${questionData?.image}`} className='ans_img' />
          </div>
        )}
      </div>
    </div>
  );
}

export default AnswerCard;
