import React, { useEffect, useState } from 'react'
import OTPInput from "otp-input-react";
import { BASEPATH } from "../../services/config";
import "./style.css";
import PopUp from '../../components/PopUp';
import { useDispatch, useSelector } from 'react-redux';
import { Notify } from 'notiflix';
import { authenticate, validate } from '../../features/authSlice';
import { fetchKidsList } from '../../features/sharedSlice';
import { useNavigate } from 'react-router-dom';
import Base from '../../components/Layout/Base';
import step1 from "../../assets/icons/step1.svg"
import { registerSteps } from './data';
import RegisterStep from './components/RegisterStep';

const Landing = () => {


  const initFormData = { mobile: "", secret: "" }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);


  const [loginModal, setLoginModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState('')
  const [formData, setFormData] = useState(initFormData)

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/participant', { replace: true });
    }
    document.body.classList.add('ict-theme');
    return () => {
      document.body.classList.remove('ict-theme');
      document.body.style.backgroundImage = "";
    };
  }, [isAuthenticated]);

  const handleInputChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(authenticate({ mobile: formData?.mobile })).unwrap();
      if (resultAction.status) {
        Notify.success(resultAction.message);
        setOtpModal(true);
      } else {
        Notify.warning(resultAction.message);
      }
    } catch (err) {
      Notify.failure('Failed to send OTP. Please try again.');
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(validate({ mobile: formData?.mobile, secret: otp })).unwrap();
      if (resultAction.data.status) {
        Notify.success(resultAction.data.message);
        dispatch(fetchKidsList());
        navigate("/participant", { replace: true })
      } else {
        Notify.warning(resultAction.data.message);
      }
    } catch (err) {
      Notify.failure('Invalid OTP. Please try again.');
    }
  };

  return (
    <Base>
      <div className="text-center welcome-sec container">
        {/* <img src={`${BASEPATH}/images/ict-quiz/classmate-logo.png`} className='classmate-logo' /> */}
        <h1 className="my-4 text-white">School Round</h1>
        <div className="gr-txt fw-500 d-flex justify-content-center w-100 py-4">
          <div className="quicksand fs-5">Welcome to the School Round of the Classmate All Rounder Competition!</div>
        </div>
        <img src={`${BASEPATH}/images/ict-quiz/ict.png`} className="landing-img mt-2 mt-lg-5" />
        <div className="intro-para-txt quicksand mt-0 mb-5">
          <span className='fw-500 mb-5'>You’ve Started your journey of becoming the Classmate All-Rounder Champion </span>
          <span className='fw-500'>This first stage, the <div className="fw-bolder fs-5 d-inline">School Round</div>, is an online, proctored IQ quiz centered around six key pillars of development outlined in the</span>
          <span className="text-yellow">National Education Policy (NEP).</span>
          <span className='fw-500'>Top performers will advance to the <div className="fw-bolder fs-5 d-inline">City Round</div> for a chance to showcase their skills at the next level. </span>
        </div>
        <div className="gr-txt quicksand intro-para-txt fw-500">Each participant will receive a <div className="text-yellow fs-5 d-inline">Personalized Holistic Development Card</div> after the quiz, which will provide feedback and guidance to support their growth across these essential pillars.</div>

        <div className="text-start">
          <div className="txt-yellow fs-5">Registration Instructions</div>
          <div className="quicksand intro-para-txt text-start my-0">
            <span>Please read the instructions carefully to ensure successful registration</span>
          </div>
          <div className='landing-steps'>
            {registerSteps?.map(data => <RegisterStep data={data} />)}
          </div>
        </div>
        <div className="intro-para-txt quicksand mt-0">
          <span>Get ready to unlock your potential!</span>
          <span>Once you’re registered, additional quiz details will be available.</span>
        </div>
        <button className="site-btn my-4 border-0" onClick={() => setLoginModal(true)}>
          Get Started!
        </button>
        <PopUp show={loginModal} handleClose={() => setLoginModal(false)}>
          {!otpModal ? <form onSubmit={handleSubmit}>
            <div className="p-4">
              <div className="otp-popup-title">
                <h2>Enter your Mobile No</h2>
                <p>to receive OTP</p>
              </div>
              <div className="input-group mb-3 placeholder-line">
                <span className='input-placeholder'>+91</span>
                <input type="tel" maxLength={10} minLength={10} pattern='[0-9]{10}' required name="mobile" value={formData?.mobile} onChange={handleInputChange} placeholder="" className="form-control" />
              </div>
              <div className="d-flex flex-column otp-submit-btn">
                <button type="submit" disabled={formData?.mobile?.length < 10} className="site-btn">Send OTP</button>
              </div>
            </div>
          </form>
            : <form>
              <div className="p-4">
                <div className="otp-popup-title">
                  <h2>Enter OTP</h2>
                  <p>sent to your Mobile No</p>
                </div>
                <div className="otp-input-root">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                </div>
                <div className="d-flex flex-column otp-submit-btn">
                  <button onClick={handleOtpSubmit} className="site-btn mb-3">Submit OTP</button>
                  <button onClick={handleSubmit} className="site-btn resend">Resend OTP</button>
                </div>
              </div>
            </form>
          }
        </PopUp>
      </div>
    </Base>
  )
}

export default Landing 