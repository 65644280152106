import React, { useState } from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import videoicon from "../../../assets/images/videoicon.png";
import righticon from "../../../assets/images/righticon.png";
import leftIcon from "../../../assets/images/lefticon.png";
import videoImg from "../../../assets/images/videoImg.png";

import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
export const Testimonial = () => {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleShow = (url) => {
    setVideoUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setVideoUrl("");
  };

  function PrevArrow(props) {

    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow arrow-main arrow-prev ${className}`} >
        <img src={leftIcon} />
      </div>
    );
  }
  
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow arrow-main arrow-next ${className}`} >
        <img src={righticon}  />
      </div>
    );
  
  }
  
  const settings = {
    dots: false,
    infinite: false,
    autoplay:true,
    speed: 6000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      }
    ],
  };
  return (
    <div className="container">
        <div className='rp-testimonial'>
          <Slider {...settings}>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
                <div className="video-thumbnail">
                  <img src={videoImg} alt="Video Thumbnail" />
                  <span className="play-button" onClick={() => handleShow("https://www.youtube.com/embed/OEtid9dU070")}>
                    <img src={videoicon} alt="Play" />
                  </span>
                </div>
          </Slider>
        </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Watch Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="315"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}