import { objToQueryString } from "../helper";
import API from "./API";

const generatePresignedURL = async params => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/upload/get-presigned-url?${queryString}`);
  return response.data;
};

export default { generatePresignedURL };
