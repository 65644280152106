import React, { useState } from "react";
import PopUp from "../../components/PopUp/index";

export const StepThreeContent = () => {

  const [show, setShow] = useState(false)

  return (
    <div className="container step-three-content montserrat">
      <h2 className="mt-4 mt-md-0">Submit your entry now!</h2>
      <p className="mt-2 mt-md-3 mb-5 pb-0 pb-md-5">No submissions yet</p>
      <img src="../images/register-nrc/step3.png" alt="" className="mw-100" />
      <div className="d-flex justify-content-center py-5">
        <button type="button" className="site-btn white-btn montserrat px-5" onClick={() => setShow(true)}>Submit Now</button>
      </div>
      <PopUp size="lg" show={show} handleClose={() => setShow(false)}>
        <div className="register-nrc-modal">
          <div className="modal-header d-flex justify-content-end border-0">
            <button onClick={() => setShow(false)} type="button" className="modal-close border-0 bg-transparent"> <img src="../../images/close.png" /></button>
          </div>
          <div className="register-nrc-modal-body p-3 pt-0 p-xl-5 pt-xl-0">
            <h2 className="register-nrc-modal-title montserrat mt-0">
              Make your Submission  here
              <span>File upload (Max 5 files; 1GB)*</span>
            </h2>
            <input type="file" className="my-5" />
            <div className="uploaded-file-list">
              <div className="uploaded-file-item">
                <div className="uploaded-file-icon">
                  <img src="../images/register-nrc/file1.png" />
                  <a href="#" className="close"><img src="../images/register-nrc/close.png" /></a>
                </div>
                <span className="uploaded-file-name montserrat">Group 11009947.png</span>
              </div>
              <div className="uploaded-file-item">
                <div className="uploaded-file-icon">
                  <img src="../images/register-nrc/file2.png" />
                  <a href="#" className="close"><img src="../images/register-nrc/close.png" /></a>
                </div>
                <span className="uploaded-file-name montserrat">Group 11009947.png</span>
              </div>
              <div className="uploaded-file-item">
                <div className="uploaded-file-icon">
                  <img src="../images/register-nrc/file3.png" />
                  <a href="#" className="close"><img src="../images/register-nrc/close.png" /></a>
                </div>
                <span className="uploaded-file-name montserrat">Group 11009947.png</span>
              </div>
              <div className="uploaded-file-item">
                <div className="uploaded-file-icon">
                  <img src="../images/register-nrc/file2.png" />
                  <a href="#" className="close"><img src="../images/register-nrc/close.png" /></a>
                </div>
                <span className="uploaded-file-name montserrat">Group 11009947.png</span>
              </div>
              <div className="uploaded-file-item">
                <div className="uploaded-file-icon">
                  <img src="../images/register-nrc/file2.png" />
                  <a href="#" className="close"><img src="../images/register-nrc/close.png" /></a>
                </div>
                <span className="uploaded-file-name montserrat">Group 11009947.png</span>
              </div>
            </div>
            <div className="file-progess quiz-progress-bar">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: '90%' }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <h6 className="file-percantage montserrat mt-2">90%</h6>
            <div className="upload-err montserrat"><img src="../../images/register-nrc/err.png" />Please wait until server side processing of file is complete</div>
            <div className="d-flex justify-content-center mt-4">
              <button type="button" className="site-btn blue-theme-btn blue-them-shadow montserrat d-inline-block" onClick={() => setShow(true)}>Submit Now</button>
              <button type="button" className="site-btn gray-btn  montserrat d-inline-block" onClick={() => setShow(true)}>Submit Now</button>
            </div>
          </div>
        </div>
      </PopUp>
      {/* <PopUp size="lg" show={show} handleClose={() => setShow(false)}>
        <div className="register-nrc-modal">
            <div className="modal-header">
              <h2 className="register-nrc-modal-title montserrat text-start mb-0">CAR Submission</h2>
            </div>
            <div className="register-nrc-modal-body p-3  p-md-5">
              <p className="montserrat modal-para">You are submitting for <b>Test_Child_1</b> in <b>NARC 2024-25</b> under the activity <b>Coding</b></p>
            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="site-btn blue-theme-border-btn montserrat px-5 btn-270">No</button>
                <button type="button" className="site-btn blue-theme-btn montserrat px-5 btn-270">Yes</button>
            </div>
        </div>
      </PopUp> */}
      {/* <PopUp size="lg" show={show} handleClose={() => setShow(false)}>
        <div className="register-nrc-modal">
            <div className="modal-header">
              <h2 className="register-nrc-modal-title montserrat text-start">Terms</h2>
            </div>
            <div className="modal-body text-start">
              <p className="montserrat modal-para">Please read and accept the terms of use</p>
              <div className="checkbox-items mt-3 mt-md-5">
                <div className="form-check mb-3 mb-md-5">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label montserrat" for="flexCheckDefault">
                      I allow KidEx to share my submission with public for benefit of others.
                  </label>
                </div>
                <div className="form-check mb-3 mb-md-5">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label montserrat" for="flexCheckDefault">
                  I agree to abide by all the rules & regulations as defined & understand that violation of any rules or guideline may result in disqualification from the event
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label montserrat" for="flexCheckDefault">
                  I give consent to KidEx to save my submission on their servers in India/outside as deemed appropriate by them
                  </label>
                </div>
                
              </div>
            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="site-btn blue-theme-border-btn btn-270 montserrat montserrat px-5">Cancel</button>
                <button type="button" className="site-btn blue-theme-btn btn-270  montserrat px-5">Accept & Submit</button>
            </div>
        </div>
      </PopUp> */}
    </div>
  );
};
