import API from "./API"

const createUser = async (payload) => {
  const response = await API.post(`/api/entity/kid/logged-in/create`, payload);
  return response.data;
};

const editKid = async (payload) => {
  const response = await API.put(`/api/entity/kid/logged-in/update`, payload);
  return response.data;
};

const registerToEvent = async (payload) => {
  const response = await API.post(`/api/register/kid/register/free`, payload);
  return response.data;
};

export default {
  createUser,
  editKid,
  registerToEvent
};