import React from "react";
import "./style.css";
export const Steps = () => {
  return (
    <div className="container  step-container">
      <ul className="vcv-timeline my-3 my-md-5 nunito pt-2 pt-md-5">
        <li className="vcv-timeline-item vcv-step-completed" data-step="1" data-step-title="Download">
          <span>Project Details</span>
        </li>
        <li className="vcv-timeline-item  vcv-step-process" data-step="2">
          <span>Video Tutorials</span>
        </li>
        <li className="vcv-timeline-item" data-step="3">
          <div className="step-hover">Step 3</div>
          <span>Submission</span>
        </li>
      </ul>
    </div>
  );
};
