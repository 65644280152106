import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import DatePicker from 'react-date-picker';
import PopUp from "../../components/PopUp";
import { Notify } from 'notiflix';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchClasssList, fetchKidsList, fetchSchoolList } from '../../features/sharedSlice';
import { enrollToEvent, registerUser } from '../../features/userSlice';
import Base from '../../components/Layout/Base';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import InfoIcon from '../../assets/icons/InfoIcon';

const Register = () => {

  const initFormData = {
    name: "",
    date_of_birth: "",
    gender: "",
    kidClass: "",
    class_section: "",
    roll_no: "",
    school_registration: "",
    school: "",
    city: "",
    emailId: "",
    mobile_no: "",
  };

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const { cityList, classList, eventDetails } = useSelector(state => state.shared);
  const { loading } = useSelector(state => state.user);

  const [successModal, setSuccessModal] = useState(false);
  const [formData, setFormData] = useState({ ...initFormData, mobile_no: user?.user?.mobile_no });
  const [tnc, setTnc] = useState(false);
  const [schools, setSchools] = useState([]);
  const [kidId, setKidId] = useState();

  const [value, onChange] = useState(new Date());

  const cities = cityList?.map((item) => ({
    key: item.id,
    label: item.appString,
    value: item.appString,
    name: "city"
  }));

  const classes = classList?.map((item) => ({
    key: item.id,
    label: item.className,
    value: item.className,
    name: "kidClass"
  }));

  const genderList = ["Male", "Female", "Other"]?.map((item) => ({
    key: item,
    label: item,
    value: item,
    name: "gender"
  }));

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    };
  }, []);

  useEffect(() => {
    dispatch(fetchClasssList());
  }, [])

  const handleInputChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleDateChange = value => setFormData({ ...formData, date_of_birth: value })

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const payload = {
        ...formData,
        school: formData?.school.label,
        city: formData?.city.label,
        gender: formData?.gender.label,
        kidClass: formData?.kidClass.label,
      }
      const resultAction = await dispatch(registerUser(payload)).unwrap();
      if (resultAction.status) {
        await dispatch(fetchKidsList());
        registerKidForEvent(resultAction?.body?.kid?.id);
        setKidId(resultAction?.body?.kid?.id)
      } else {
        Notify.warning(resultAction.message);
      }
    } catch (err) {
      Notify.failure('Failed to Register. Please try again.');
    }
  }

  const registerKidForEvent = async (kidId) => {
    try {
      const postData = {
        competition_id: eventDetails?.event_id,
        email_id: formData?.emailId,
        kid_id: kidId,
        registration_type: "OPEN"
      }
      const resultAction = await dispatch(enrollToEvent(postData)).unwrap()
      if (resultAction.status) {
        Notify.success(resultAction.message);
        setSuccessModal(true);
      } else {
        Notify.warning(resultAction.message);
      }
    } catch (err) {
      Notify.failure('Failed to Register. Please try again.');
    }
  }

  const handleSelectChange = (option) => {
    setFormData({ ...formData, [option.name]: option })
  }

  const schoolChangeHandler = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const resultAction = await dispatch(fetchSchoolList(value)).unwrap();
      if (resultAction.status) {
        const data = resultAction?.data;
        let schoolList = data.map(item => ({
          key: item.id,
          label: item.displayName,
          value: item.id,
          name: "school"
        }))
        schoolList.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
        setSchools(schoolList);
      } else {
        setSchools([]);
      }
    }
  };

  return (
    <Base>
      <Tooltip id={`email-hint`} place="top" />
      <div className="container basic-form-details">
        <h1 className="my-5">Enter Participant’s Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Full Name*</label>
                <input type="text" required name="name" value={formData?.name} onChange={handleInputChange} className="form-control" placeholder="Enter your name" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Date of Birth*</label>
                <div className="position-relative">
                  <DatePicker
                    required
                    onChange={handleDateChange}
                    value={formData?.date_of_birth}
                    maxDate={new Date()}
                  />
                  {/* <input type="date" name="date_of_birth" value={formData?.date_of_birth} onChange={handleInputChange} className="form-control" placeholder="Enter your DOB" /> */}
                  {/* <img src="./images/ict-quiz/calendar.svg" className="dob-icon" /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Gender*</label>
                <Select
                  required
                  value={formData?.gender}
                  onChange={handleSelectChange}
                  options={genderList}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Class*</label>
                <Select
                  required
                  value={formData?.kidClass}
                  onChange={handleSelectChange}
                  options={classes}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Section</label>
                <input type="text" name="class_section" value={formData?.class_section} onChange={handleInputChange} className="form-control" placeholder="Enter your section" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Roll No.</label>
                <input type="text" name="roll_no" value={formData?.roll_no} onChange={handleInputChange} className="form-control" placeholder="Enter roll no" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">School Registration No</label>
                <input type="text" name="school_registration" value={formData?.school_registration} onChange={handleInputChange} className="form-control" placeholder="Enter your school registration no" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">School*</label>
                <Select
                  required
                  value={formData?.school}
                  onChange={handleSelectChange}
                  options={schools}
                  onKeyDown={schoolChangeHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">City*</label>
                <Select
                  required
                  value={formData?.city}
                  onChange={handleSelectChange}
                  options={cities}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Personal Email ID*
                  <span data-tooltip-id="email-hint" data-tooltip-content="Enter the Participant's or Guardian's email ID. All event information will be communicated via email." ><InfoIcon /></span>
                </label>
                <input type="text" required name="emailId" value={formData?.emailId} onChange={handleInputChange} className="form-control" placeholder="Enter email id" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">Phone Number*</label>
                <input type="number" maxLength={10} required readOnly name="mobile_no" value={formData?.mobile_no} onChange={handleInputChange} className="form-control" placeholder="Enter phone number" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mw-100">
                <label className="checkbox-container my-3 d-inlinr-block">
                  <input type="checkbox" required onChange={e => setTnc(e.target.checked)} checked={formData?.tnc} /><span className="checkboxmark"></span>
                  Agree to <button type="button" className="terms-link">Terms and Conditions</button>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group text-center mw-100">
                <button type="submit" className="site-btn" disabled={!tnc || loading}>{loading ? 'Registering...' : 'Submit'}</button>
              </div>
            </div>
          </div>
        </form>
        <PopUp show={successModal}>
          <div className="p-4">
            <div className="otp-popup-title">
              <h2>Hurray!</h2>
              <img src="./images/ict-quiz/completed.png" />
              <p>You’ve completed the Registration</p>
            </div>
            <div className="d-flex flex-column otp-submit-btn">
              <Link to="/dashboard" replace={true} state={{ kidId }} className="site-btn value-fill mb-3">Proceed</Link>
            </div>
          </div>
        </PopUp>
      </div>
    </Base>
  )
}

export default Register