import React from 'react';
import { Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from "../../assets/images/ict-quiz/logo.png";
import LeftArrow from "../../assets/images/ict-quiz/LeftArrow.png"
import { logoutUser } from '../../features/authSlice';
import { BASEPATH } from '../../services/config';
import '../../Pages/Participant/style.css';
import togglerImg from "../../assets/images/toggler.png"

const Navigation = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(state => state.auth);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/signup");
  }
  const menuClickAddBodyClass = () => {
    document.body.classList.add("handle-menu");
  };
  const closeClickRemoveBodyClass = () => {
    document.body.classList.remove("handle-menu");
  };
  return (
    <header className="text-center header-section">
      <div className="container">
        <div className='d-flex justify-content-between align-items-center round-menu'>
          <Link to={isAuthenticated ? "/participant" : "/"}><img src={logoImage} alt="Logo" className="logo" /></Link>
          {isAuthenticated ?
            <Button onClick={handleLogout} variant="link" className="text-white text-decoration-none p-0 w-auto">
              <img src={LeftArrow} alt='' className='pe-2' />
              Log Out
            </Button>
            :
            <nav class="navbar navbar-expand-lg navbar-dark">
              <div class="container">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                  onClick={menuClickAddBodyClass}
                >
                  <span class="toggler-btn"><img src={togglerImg} alt='' /></span>
                </button>

                <div
                  class="offcanvas offcanvas-start"
                  tabindex="-1"
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                  onClick={closeClickRemoveBodyClass}
                >
                  <div class="offcanvas-header">
                    <button
                      type="button"
                      class="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"

                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    <ul class="navbar-nav">
                      <li class="nav-item active">
                        <Link to={isAuthenticated ? "/participant" : "/"} class="nav-link"><i class="bi bi-house-door me-2"></i> Home</Link>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-person me-2"></i> About</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-clipboard-check me-2"></i> Eligibility</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-diagram-3 me-2"></i> Process</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-trophy me-2"></i> Gratification</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-image me-2"></i> Gallery</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-telephone me-2"></i> Contact Us</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"><i class="bi bi-question-circle me-2"></i> FAQs</a>
                      </li>
                      <li class="nav-item navigation-register-btn">
                        <Link to="/signup" replace={true} class="btn-register" href="#">Register Now</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          }
        </div>
      </div>
    </header>
  );
};

export default Navigation;
