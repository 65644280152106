import React from "react";
import "./style.css";
import { Banner } from "./banner";
import { Steps } from "./steps";
import { StepOneContent } from "./stepOneContent";
import { StepTwoContent } from "./stepTwoContent";
import { StepThreeContent } from "./stepThreeContent";
import { SubmitActivityContent } from "./submitActivity/submitActivityContent";
const RegisterNrcActivities = () => {
  return (
    <div className="bg-blue-theme">
      <Banner />
      <Steps />
      <StepOneContent />
      <StepTwoContent />
      <StepThreeContent />
      <SubmitActivityContent />
    </div>
  );
};

export default RegisterNrcActivities;
