import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.css";
import PopUp from '../../components/PopUp';
import { BASEPATH } from '../../services/config';
import Base from '../../components/Layout/Base';

const MicrophonePermission = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const eventId = location.state?.eventId;
  const kidId = location?.state?.kidId;
  const acm_quiz_id = location?.state?.acm_quiz_id;
  const permission = location?.state?.permission;

  const [showModal, setShowModal] = useState(false);
  const [mainStream, setMainStream] = useState(null);

  const getMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMainStream(stream);
      navigate(`/quiz/fullscreen`, {
        state: { kidId, acm_quiz_id, eventId, permission },
        replace: true
      });
    } catch (error) {
      console.error('Microphone permission denied', error);
    }
  }

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
      if (mainStream) {
        mainStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mainStream]);

  const proceedQuiz = () => {
    setShowModal(false);
    navigate(`/quiz/fullscreen`, {
      state: { kidId, acm_quiz_id, eventId, permission },
      replace: true
    });
  }

  return (
    <Fragment>
      <div className="quiz-name-page ict-quiz pt-2 pt-md-5  mt-3 mt-md-0">
        <div className='container'>
          <div className='quiz-row bg-transparent text-center mb-5  py-4 py-md-5'>
            <div className='permission-content'>
              <div className="icon-circle">
                <img src={`${BASEPATH}/images/quiz/microphone-white.svg`} />
                <span><img src={`${BASEPATH}/images/quiz/required-white.svg`} /> </span>
              </div>
              <h2 className='Quicksand text-white'>Microphone Permission Required</h2>
              <h4 className='Quicksand text-white'>Your data is absolutely safe with us</h4>
              <div className='quiz-control-btn white-theme-btn permi-btn flex-column justify-content-center align-items-center pt-3'>
                <button className='quiz-btn mb-3 px-3 w-280 mw-230' onClick={getMicrophonePermission}>Enable Permission</button>
                <button onClick={() => { setShowModal(true) }} className='quiz-btn quiz-border-btn px-3 w-280 mw-230' data-bs-toggle='modal' data-bs-target='#microphone-permission'>Not Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp show={showModal} handleClose={() => setShowModal(false)}>
        <div className='modal-camera-perm'>
          <img src={`${BASEPATH}/images/quiz/retry.svg`} />
          <h2>Are you sure you want to proceed with the unproctored version? Your overall score may be affected.</h2>
          <button type="button" onClick={proceedQuiz} className='quiz-btn mb-3 px-5 w-280 mw-230'>Continue</button>
          <button type="button" onClick={() => setShowModal(false)} className='quiz-btn btn-outline mb-3 px-5 w-280 mw-230'>Cancel</button>
        </div>
      </PopUp>
    </Fragment>
  )
}

export default MicrophonePermission