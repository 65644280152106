import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../services/authService';
import { clearState } from '../utils';
import { unsubscribe } from '../store';

const initialState = {
  user: null,
  token: null,
  email: "",
  otpSent: false,
  isAuthenticated: false,
  loading: false,
  msg: "",
  error: null,
};

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async ({ mobile }, { rejectWithValue }) => {
    try {
      const res = await authService.authenticate({ mobile });
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const validate = createAsyncThunk(
  'auth/validate',
  async ({ mobile, secret }, { rejectWithValue }) => {
    try {
      const { data, token } = await authService.validate({ mobile, secret });
      return { data, token };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      clearState();
      unsubscribe();
      return {}
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.loading = false;
        state.msg = action.payload?.message;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validate.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.status && action.payload.data?.body;
        state.token = action.payload.data.status && action.payload.token;
        state.isAuthenticated = action.payload.data.status;
      })
      .addCase(validate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = authSlice.actions;
export default authSlice.reducer;
