import React, { useEffect } from 'react'
import Base from '../../components/Layout/Base'
import "./style.css"
import { Banner } from './Component/Banner';
import { Introduction } from './Component/Introduction';
import { Eligibility } from './Component/Eligibility';
import { Stages } from './Component/Stages';
import { Rewards } from './Component/Rewards';
import { Participate } from './Component/Participate';
import { Impact } from './Component/Impact';
import { Rounder } from "./Component/Rounder"
import { Testimonial } from './Component/Testimonial';
import { Frequently } from './Component/Frequently';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const Index = () => {

  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/participant', { replace: true });
    }
  }, [isAuthenticated]);


  return (
    <Base>
      <div className='page-bg'>
        <Banner />
        <div className='py-4 py-md-5'>
          <h2 className='cm-title montserrat pt-3 container'>Introduction</h2>
          <Introduction />
        </div>
        <Eligibility />
        <div className='py-4 mt-4 py-md-5 mt-md-5'>
          <h2 className='cm-title montserrat py-3 container'>Competition Stages</h2>
          <Stages />
        </div>
        <div className='py-4 mt-4 py-md-5 mt-md-5'>
          <h2 className='cm-title montserrat py-3 container'>Rewards & Recognition</h2>
          <Rewards />
        </div>
        <div className='py-4 mt-4 py-md-5 mt-md-5'>
          <h2 className='cm-title montserrat py-3 container'>How to Register for the Classmate All <br /> Rounder Competition</h2>
          <Rounder />
        </div>
        <div className='ppy-4 mt-4 py-md-5 mt-md-5'>
          <h2 className='cm-title montserrat py-3 container'>Why Participate?</h2>
          <Participate />
        </div>
        <div className='py-4 mt-4 py-md-5 mt-md-5'>
          <h2 className='cm-title montserrat py-3  container'>Our Impact So Far</h2>
          <Impact />
        </div>
        <div className='mt-4 py-5 mt-md-5 rp-testimonial-main'>
          <h2 className='cm-title montserrat  py-3 container'>Participant Testimonial</h2>
          <Testimonial />
        </div>
        <div className='py-4 py-md-5 faq-sec'>
          <h2 className='cm-title montserrat mb-3  container'>Frequently Asked Questions (FAQs)</h2>
          <Frequently />
        </div>
      </div>

    </Base>
  )
}
