import API from "../services/API"
import { TENANT_KEY } from "../services/config";
import store from "../store";
import { createBrowserHistory } from "history";
import { logoutUser } from "../features/authSlice";

const history = createBrowserHistory();

const whitelistedRoutes = [];
const isWhitelisted = (url) => {
  return whitelistedRoutes.some(route => url.includes(route));
}

API.interceptors.request.use(request => {
  const currentState = store.getState();
  const token = currentState.auth.token;
  if (isWhitelisted(request?.url)) {
    request.headers["kid-ex-tenant-id"] = TENANT_KEY;
  } else if (token) {
    request.headers["access-token"] = token;
  } else if (!request?.url?.includes("api/auth/token/validate")) {
    request.headers["kid-ex-tenant-id"] = TENANT_KEY;
  }
  request.headers["kid-ex-client"] = "website";

  return request;
});

API.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    store.dispatch(logoutUser());
    localStorage.removeItem("token");
    history.push("/login");
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});