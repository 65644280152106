import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export const Frequently = () => {
    return (
        <>

            <div className="container py-5">
    {/* {/ Accordion Item 1 /} */}
    <div className="accordion" id="faqAccordion1">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne1">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1">
                    Who is eligible to participate?
                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#faqAccordion1">
                <div className="accordion-body">How can a school register</div>
            </div>
        </div>
    </div>
{/* 
    {/ Accordion Item 2 /} */}
    <div className="accordion" id="faqAccordion2">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne2">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2">
                How can a school register                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne2" className="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#faqAccordion2">
                <div className="accordion-body">You can register online through our website.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 3 /} */}
    <div className="accordion" id="faqAccordion3">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne3">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3">
                Who coordinates with event organizers                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne3" className="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#faqAccordion3">
                <div className="accordion-body">The event lasts for three hours each day.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 4 /} */}
    <div className="accordion" id="faqAccordion4">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne4">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4">
                Are there additional requirements for participants                     <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne4" className="accordion-collapse collapse" aria-labelledby="headingOne4" data-bs-parent="#faqAccordion4">
                <div className="accordion-body">Participation is free for all eligible participants.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 5 /} */}
    <div className="accordion" id="faqAccordion5">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne5">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5">
                Is there a dress code?                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne5" className="accordion-collapse collapse" aria-labelledby="headingOne5" data-bs-parent="#faqAccordion5">
                <div className="accordion-body">The event will be held at the main city park.</div>
            </div>
        </div>
    </div>
{/* 
    {/ Accordion Item 6 /} */}
    <div className="accordion" id="faqAccordion6">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne6">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne6">
                What time should participants arrive?                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne6" className="accordion-collapse collapse" aria-labelledby="headingOne6" data-bs-parent="#faqAccordion6">
                <div className="accordion-body">Various sports, arts, and science activities are planned.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 7 /} */}
    <div className="accordion" id="faqAccordion7">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne7">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne7">
                Can students with medical conditions participate?                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne7" className="accordion-collapse collapse" aria-labelledby="headingOne7" data-bs-parent="#faqAccordion7">
                <div className="accordion-body">Yes, prizes will be awarded to top performers in each category.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 8 /} */}
    <div className="accordion" id="faqAccordion8">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne8">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne8">
                Can participants wear costumes?                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne8" className="accordion-collapse collapse" aria-labelledby="headingOne8" data-bs-parent="#faqAccordion8">
                <div className="accordion-body">Participants should wear comfortable sportswear.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 9 /} */}
    <div className="accordion" id="faqAccordion9">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne9">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne9">
                Are food and refreshments provided?
                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne9" className="accordion-collapse collapse" aria-labelledby="headingOne9" data-bs-parent="#faqAccordion9">
                <div className="accordion-body">Yes, parents are welcome to watch and support.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 10 /} */}
    <div className="accordion" id="faqAccordion10">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne10">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne10">
                Expected participants behaviour?                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne10" className="accordion-collapse collapse" aria-labelledby="headingOne10" data-bs-parent="#faqAccordion10">
                <div className="accordion-body">Yes, refreshments will be provided for all participants.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 11 /} */}
    <div className="accordion" id="faqAccordion11">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne11">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne11">
                School Coordinator’s Role
                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne11" className="accordion-collapse collapse" aria-labelledby="headingOne11" data-bs-parent="#faqAccordion11">
                <div className="accordion-body">First aid and medical assistance will be available onsite.</div>
            </div>
        </div>
    </div>

    {/* {/ Accordion Item 12 /} */}
    <div className="accordion" id="faqAccordion12">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne12">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne12">
                How are partcipants forms managed?
                    <span className="icon-plus-minus ms-auto"></span>
                </button>
            </h2>
            <div id="collapseOne12" className="accordion-collapse collapse" aria-labelledby="headingOne12" data-bs-parent="#faqAccordion12">
                <div className="accordion-body">You can contact us at mailto:info@eventsite.com.</div>
            </div>
        </div>
    </div>
</div>

        </>
         
    );
};
