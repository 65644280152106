import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sharedService from '../services/sharedService';

const initialState = {
  kidsList: [],
  classList: [],
  schoolList: [],
  cityList: [],
  eventDetails: {},
  activeKid: {},
  loading: false,
  error: null,
};

export const fetchKidsList = createAsyncThunk(
  'shared/fetchKidsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await sharedService.getKidsList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchClasssList = createAsyncThunk(
  'shared/fetchClasssList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await sharedService.getClassList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchSchoolList = createAsyncThunk(
  'shared/fetchSchoolList',
  async (keyword, { rejectWithValue }) => {
    try {
      const response = await sharedService.getSchoolList(keyword);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchEventByName = createAsyncThunk(
  'shared/fetchEventByName',
  async (params, { rejectWithValue }) => {
    try {
      const response = await sharedService.getEventByName(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchCateoryList = createAsyncThunk(
  'shared/fetchCateoryList',
  async (params, { rejectWithValue }) => {
    try {
      const response = await sharedService.getCateoryList(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchActivities = createAsyncThunk(
  'shared/fetchActivities',
  async (params, { rejectWithValue }) => {
    try {
      const response = await sharedService.getActivities(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchCityList = createAsyncThunk(
  'shared/fetchCityList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await sharedService.getCityList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKidsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchKidsList.fulfilled, (state, action) => {
        state.loading = false;
        state.kidsList = action.payload?.body;
      })
      .addCase(fetchKidsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchClasssList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClasssList.fulfilled, (state, action) => {
        state.loading = false;
        state.classList = action.payload;
      })
      .addCase(fetchClasssList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSchoolList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolList.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolList = action.payload?.data;
      })
      .addCase(fetchSchoolList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEventByName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventByName.fulfilled, (state, action) => {
        state.loading = false;
        state.eventDetails = action.payload?.data;
      })
      .addCase(fetchEventByName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCateoryList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCateoryList.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchCateoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCityList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCityList.fulfilled, (state, action) => {
        state.loading = false;
        state.cityList = action.payload?.data;
      })
      .addCase(fetchCityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default sharedSlice.reducer;
