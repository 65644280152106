import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import quizService from '../services/quizService';

const initialState = {
  history: [],
  quizData: {},
  questionData: {},
  localData: {},
  quizSummary: {},
  activity: [],
  certificate: {},
  proctorData: {},
  reportCard: {},
  loading: false,
  error: null,
};

export const fetchHistory = createAsyncThunk(
  'quiz/fetchHistory',
  async (params, { rejectWithValue }) => {
    try {
      const response = await quizService.getHistory(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startQuiz = createAsyncThunk(
  'quiz/startQuiz',
  async (params, { rejectWithValue }) => {
    try {
      const response = await quizService.initQuiz(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuizQuestion = createAsyncThunk(
  'quiz/fetchQuizQuestion',
  async (params, { rejectWithValue }) => {
    try {
      const response = await quizService.getQuestion(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkQuizQuestion = createAsyncThunk(
  'quiz/checkQuizQuestion',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await quizService.checkQuestion(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const completeQuiz = createAsyncThunk(
  'quiz/completeQuiz',
  async (params, { rejectWithValue }) => {
    try {
      const response = await quizService.finishQuiz(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitProctoringData = createAsyncThunk(
  'quiz/submitProctoringData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.saveProctoringData(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuizSummary = createAsyncThunk(
  'quiz/fetchQuizSummary',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.getQuizSummary(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCertificate = createAsyncThunk(
  'quiz/fetchCertificate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.getCertificate(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setQuizData: (state, action) => {
      state.quizData = action.payload
    },
    setLocalData: (state, action) => {
      state.localData = { ...state.quizData, ...action.payload }
    },
    setProctorData: (state, action) => {
      state.proctorData = action.payload
    },
    resetQuizData: (state, payload) => {
      state.quizData = {}
      state.localData = {}
    },
    resetQuiz: state => {
      state = { ...initialState }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHistory.fulfilled, (state, action) => {
        state.loading = false;
        const localData = {
          quiz_attempts: action.payload?.data?.[0].quiz_attempts ?? [],
          show_complete_button: action.payload?.data?.[0]?.show_complete_button,
          show_start_button: action.payload?.data?.[0]?.show_start_button,
          quiz_time_remaining: action.payload?.data?.[0]?.quiz_time,
          show_retake_button: action.payload?.data?.[0]?.show_retake_button,
          summary_button: action.payload?.data?.[0]?.summary_button,
        }
        const proctorData = {
          quiz_time: action.payload?.data?.[0]?.quiz_time,
          is_proctoring: action.payload?.data?.[0]?.is_proctoring,
          proctoring_type: action.payload?.data?.[0]?.proctoring_type,
          video_proctoring_type: action.payload?.data?.[0]?.video_proctoring_type,
          video_duration: action.payload?.data?.[0]?.video_duration,
          videos_count: action.payload?.data?.[0]?.videos_count,
          images_count: action.payload?.data?.[0]?.images_count,
        }
        state.history = action.payload.data
        state.localData = localData;
        state.proctorData = proctorData
      })
      .addCase(fetchHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(startQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startQuiz.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(startQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchQuizQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuizQuestion.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.summary_button) {
          state.questionData = action.payload?.data;
        }
        const localData = {
          quiz_time_limit: action?.payload?.data?.quiz_time_limit,
          skip_button: action?.payload?.data?.skip_button,
          check_button: action?.payload?.data?.check_button,
          quiz_time_remaining: action?.payload?.data?.quiz_time_remaining ?? action?.payload?.data?.time_remaining,
          summary_button: action?.payload?.data?.summary_button,
          show_complete_button: action?.payload?.data?.quiz_complete_button,
          continue_button: action?.payload?.data?.continue_button,
          timestamp: new Date().getTime(),
          correct_options: null
        }
        state.localData = localData;
      })
      .addCase(fetchQuizQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(checkQuizQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkQuizQuestion.fulfilled, (state, action) => {
        state.loading = false;
        const localData = {
          answer_description: action.payload?.data?.answer_description,
          correct_options: action.payload?.data?.correct_options,
          finish_popup_one_button: action.payload?.data?.finish_popup_one_button,
          quiz_time_remaining: action.payload?.data?.quiz_remaining_time,
          summary_button: action.payload?.data?.summary_button,
          continue_button: action.payload?.data?.continue_button,
          check_button: action.payload?.data?.check_button,
          show_complete_button: action.payload?.data?.quiz_complete_button,
          timestamp: new Date().getTime()
        }
        state.localData = localData
      })
      .addCase(checkQuizQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(completeQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeQuiz.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(completeQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitProctoringData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitProctoringData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(submitProctoringData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCertificate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchQuizSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuizSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.reportCard = action.payload?.data;
      })
      .addCase(fetchQuizSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { clearError, setLocalData, setQuizData, setProctorData, resetQuizData, resetQuiz } = quizSlice.actions;
export default quizSlice.reducer;
