import React from 'react';
import dmmyImg from "../../../assets/images/dmmy.png";
import userImg from "../../../assets/images/user.png";
import user1Img from "../../../assets/images/user1.png";

export const Participate = () => {
    return (
        <div className="container Participate-section  ">
            <div className="row justify-content-center">
                <div className="col-12 col-md-4">
                    <div className="circle-card">
                        <div className="circle-icon">
                            <img src={dmmyImg} alt="Skill Development Icon" />
                        </div>
                        <div className="circle-title">Skill Development</div>
                        <div className="circle-text">
                            Participation supports a well-rounded skill set, including cognitive, social, and physical growth.
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="circle-card">
                        <div className="circle-icon">
                            <img src={userImg} alt="Real-World Experience Icon" />
                        </div>
                        <div className="circle-title">Real-World Experience</div>
                        <div className="circle-text">
                            Hands-on challenges help students develop practical life skills and adaptability.
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="circle-card">
                         
                             <div className="circle-icon">
                             
                            <img src={user1Img} alt="National Exposure Icon" />
                        
                        </div>
                        <div className="circle-title">National Exposure</div>
                        <div className="circle-text">
                            The multi-stage format offers participants a chance to represent their city and school on a national platform.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
