import { objToQueryString } from "../helper";
import API from "./API"

const getHistory = async (params) => {
  const { kidId } = params;
  delete params["kidId"];
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/champions/quiz/${kidId}?${queryString}`);
  return response.data;
};

const initQuiz = async params => {
  const { kidId } = params;
  delete params["kidId"];
  const queryString = objToQueryString(params);
  const response = await API.post(`/api/champions/quiz/start/${kidId}?${queryString}`);
  return response.data;
}

const getQuestion = async params => {
  const queryString = objToQueryString(params);
  const response = await API.post(`/api/champions/quiz/question?${queryString}`);
  return response.data;
}

const checkQuestion = async payload => {
  const response = await API.post(`/api/champions/quiz/question/check-options`, payload);
  return response.data;
}

const finishQuiz = async params => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/champions/quiz/complete?${queryString}`);
  return response.data;
}

const saveProctoringData = async data => {
  const response = await API.post(`/api/champions/quiz/save/proctoring`, data);
  return response.data;
}

const getQuizSummary = async params => {
  const { kidId } = params;
  delete params["kidId"];
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/champions/quiz/summary/${kidId}?${queryString}`);
  return response.data;
}

const getCertificate = async params => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/certificate/download-certificate-by-attempt?${queryString}`);
  return response.data;
}

export default {
  getHistory,
  initQuiz,
  getQuestion,
  checkQuestion,
  finishQuiz,
  saveProctoringData,
  getQuizSummary,
  getCertificate
};