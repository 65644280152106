import React from 'react'

const RegisterStep = ({ data }) => {
  return (
    <div className="quicksand reg-step d-flex justify-content-start align-items-center gap-4 mt-4">
        <div className='reg-icon'>
          <img src={data?.icon} alt="step1" />
        </div>
      <div className='step-content-right'>
        <h2 className="fw-bolder ps-3 ">{data?.sr}. {data?.title}</h2>
        <ul>
          {data?.items?.map(text => {
            return <li className='quicksand'>{text}</li>
          })}
        </ul>
      </div>
    </div>
  )
}

export default RegisterStep;