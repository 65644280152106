import { color } from "chart.js/helpers";

export const saveState = state => {
  const serializedData = JSON.stringify(state);
  localStorage.setItem("car-quiz", serializedData);

}

export const getState = () => {
  const serializedData = localStorage.getItem("car-quiz");
  if (serializedData === null)
    return undefined

  const parsedState = JSON.parse(serializedData);
  return parsedState
}

export const clearState = () => {
  localStorage.removeItem("car-quiz");
}

export const radarConfigs = {
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  tooltips: { enabled: false },
  // plugins: {
  //    legend: { display: false },
  // },
  legend: { display: false },
  scales: {
    xAxes: [{ display: false }],
    yAxes: [{ display: false }],
    r: {
      pointLabels: {
        font: {
          size: 14,
        },
        color: "#FFE14C"
      },
      min: 0,
      max: 100,
      beginAtZero: true,
      gridLines: {
        color: "black",
        lineWidth: 3,
      },
      grid: {
        color: [
          {
            color: "#FFE14C",
            backgroundColor: "#FFF",
          },
          "#FFF",
          "#FFF",
          "#FFF",
          "#FFF",
        ],
      },

      angleLines: {
        display: false,
      },
      ticks: {
        display: true,
        stepSize: 25,
        color: "#FFF",
        backdropColor: "rgba(0, 0, 0, 0)"
      },
    },
  },
};