import React from "react";
import selfIcon from "../../../assets/images/self Register.png";
import schoolIcon from "../../../assets/images/School-Organized Registration.png";
import { Link } from "react-router-dom";
export const Rounder = () => {
  return (
    <>

      <div className="registration-container container">
        <div className="registration-cards">
          <div className="full-withradius">
            <div className="card angle-graphics1  ">
              <div className="img-sec">
                <img src={selfIcon} alt="Self-Registration" className="icon" />
              </div>
              <h2>Self-Registration</h2>
              <p>
                Children can independently register by clicking here. Carefully review all
                instructions before registering.
              </p>
              <span class="angle-graphics-4"></span>
              <div className="rounder-btn-main">
                <Link to="/signup" replace={true} className="rounder-btn text-decoration-none">Register Now</Link>
              </div>
              <div className="arrow arrow-left"></div>
            </div>
          </div>
          <div className="full-withradius second-bx">
            <div className="card-sec angle-graphics1">
              <div className="img-sec">
                <img src={schoolIcon} alt="Self-Registration" className="icon" />
              </div>
              <h2>School-Organized Registration</h2>
              <p>
                Students from registered schools need not register independently; Classmate organizers will coordinate with schools. The IQ quiz for this group will be conducted in the school’s computer lab. (View list of registered schools here.)
              </p>
              <span class="angle-graphics-4"></span>
              <div className="rounder-btn-main">
                <button className="rounder-btn">List of Registered School</button>
              </div>
              <div className="arrow arrow-left"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


