import React from 'react'

export const Introduction = () => {
  return (
    <>
      <div className=' intro-section  montserrat container'>
        <div className='flex-items intro-content'>
          <div class="animated-box">
            <div className='flex-items-row angle-graphics'>
              <span className='angle-graphics-2'></span>
              <img src='../../images/in1.png' />
              <div className='py-3'>
                <h2>NEP 2020</h2>
                <p>
                  NEP 2020 emphasizes a balanced focus on academics, skills, and personal growth, moving beyond exam results to create well-rounded individuals ready for future challenges.
                </p>
              </div>
            </div>
          </div>

          <div class="animated-box">
            <div className='flex-items-row  angle-graphics color2'>
              <span className='angle-graphics-2'></span>
              <img src='../../images/in2.png' />
              <div className='py-3'>
                <h2>About Classmate</h2>
                <p>
                  Classmate champions joyful learning through products and activities, from premium notebooks to the interactive myClassmate app and hands-on learning tools like DIY Origami and Augmented Reality.
                </p>
              </div>

            </div>
          </div>
          <div class="animated-box">
            <div className='flex-items-row  angle-graphics color3'>
              <span className='angle-graphics-2'></span>
              <img src='../../images/in3.png' />
              <div className='py-3'>
                <h2>About Classmate All Rounder</h2>
                <p>
                  Classmate All Rounder invites children aged 5-17 to showcase their talents across academics, physical skills, socio-emotional development, creativity, and cultural understanding, fostering holistic growth in line with NEP 2020
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
