import moment from "moment";

export const objToQueryString = params => new URLSearchParams(params).toString();

export const toggleElementInArray = (array, element) => {
  if (array.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    return [...array, element];
  }
};

export const removeEmptyKeys = (filterObj) => {
  const obj = { ...filterObj }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length === 0) {
          delete obj[key];
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        removeEmptyKeys(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
  }
  return obj;
}

export const isString = str => str !== null && str !== undefined && str.trim() !== "";

export const getFirstVideoUrl = (files) => {
  for (let i = 0; i < files.length; i++) {
    if (files[i].fileType.toLowerCase() === "video") {
      return files[i].fileUrl;
    }
  }
  return null;
};

export const countMatchingKeys = (obj) => {
  const keys = ["gender", "tenthPercentage", "twelfthPercentage", "gradDegree", "gradCgpa", "postGradDegree", "postGradCgpa", "nativeState", "areaOfInterest", "workExperience", "isPlaced", "isShortlisted"]
  return keys.reduce((count, key) => {
    if (key in obj) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const formatFloat = (num) => {
  if (num % 1 === 0) {
    return num;
  } else {
    return num.toFixed(2);
  }
}

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';
  let fullVersion = 'Unknown';
  let majorVersion = 'Unknown';
  let browserCode = 'Unknown';
  let versionOffset;

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox';
    fullVersion = userAgent.substring(userAgent.indexOf('Firefox/') + 8);
    browserCode = 'FF';
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
    browserName = 'Samsung Internet';
    fullVersion = userAgent.substring(userAgent.indexOf('SamsungBrowser/') + 15);
    browserCode = 'SAM';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browserName = 'Opera';
    fullVersion = userAgent.indexOf('OPR') > -1
      ? userAgent.substring(userAgent.indexOf('OPR/') + 4)
      : userAgent.substring(userAgent.indexOf('Opera/') + 6);
    browserCode = 'OP';
  } else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = userAgent.substring(userAgent.indexOf('rv:') + 3);
    browserCode = 'IE';
  } else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge';
    fullVersion = userAgent.substring(userAgent.indexOf('Edge/') + 5);
    browserCode = 'ED';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome';
    fullVersion = userAgent.substring(userAgent.indexOf('Chrome/') + 7);
    browserCode = 'CH';
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari';
    fullVersion = userAgent.substring(userAgent.indexOf('Safari/') + 7);
    browserCode = 'SF';
  }

  if ((versionOffset = fullVersion.indexOf(';')) !== -1)
    fullVersion = fullVersion.substring(0, versionOffset);
  if ((versionOffset = fullVersion.indexOf(' ')) !== -1)
    fullVersion = fullVersion.substring(0, versionOffset);
  majorVersion = parseInt('' + fullVersion, 10);

  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    name: browserName,
    version: majorVersion,
    code: browserCode,
  };
};

export const getAgeByDOB = dob => Number.isFinite(dob) ? moment().diff(new Date(dob), 'y') : 0;

export const subtractNonNegative = (a, b) => Math.max(0, a - b);

export const formatSeconds = sec => sec >= 60 ? `${Math.floor(sec / 60)} mins` : `${sec} sec`;
