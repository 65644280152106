import { objToQueryString } from "../helper";
import API from "./API";

const getKidsList = async () => {
  const response = await API.get('/api/entity/kid/logged-in/fetch/list');
  return response.data;
}

const getClassList = async () => {
  const response = await API.get('/api/class/all', { data: {} });
  return response.data;
}

const getSchoolList = async (keyword) => {
  const response = await API.post(`/api/school/searchSchool/${keyword}`, {});
  return response.data;
}

const getEventByName = async (params) => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/champions/event/code?${queryString}`, { data: {} });
  return response.data;
}

const getCateoryList = async (params) => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/competition/categories?${queryString}`, { data: {} });
  return response.data;
}

const getActivities = async (params) => {
  const queryString = objToQueryString(params);
  const response = await API.get(`/api/competition/activities/age?${queryString}`, { data: {} });
  return response.data;
}

const getCityList = async () => {
  const response = await API.get(`/api/city`, { data: {} });
  return response.data;
}

export default {
  getKidsList,
  getClassList,
  getSchoolList,
  getEventByName,
  getCateoryList,
  getActivities,
  getCityList
};
