import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import monyImg from "../../../assets/images/mony.png"
import newsImg from "../../../assets/images/news.png"
import starImg from "../../../assets/images/star.png"
export const Rewards = () => {
  return (
    <>
      <div className='container'>
        <div className="reward-card cash-prizes position-relative d-flex   align-items-center">
          <img className='me-4'  src={monyImg} alt="My Image" />
          <div className='cards-text'>
            <h3>Cash Prizes</h3>
            <p>Totalling <span>INR 1,00,000/-</span>  <br/>for top performers</p>
          </div> 
        </div>
        {/* first-secton */}

        <div className="reward-card media-coverage position-relative d-flex   align-items-center">
           <img className='me-4' src={newsImg} alt="My Image" />
           <div className='cards-text'>
            <h3>Media Coverage</h3>
            <p>Top students and their schools gain front-page <br/> newspaper coverage at the City & National levels</p>
           </div>
        </div>
        {/* third-section */}

        <div className="reward-card school-championship position-relative d-flex   align-items-center">
         <img  className='me-4' src={starImg} alt="My Image" />
         <div className='cards-text'>
            <h3>School Championship</h3>
            <p>Schools have a chance to earn the Classmate <br/> All Rounder Championship title</p>
          </div>
        </div>
      </div>
    </>
  );
};
