
import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import './style.css';
import Base from '../../components/Layout/Base';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function PrevArrow(props) {

  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow arrow-main arrow-prev ${className}`} >
      <img src='../../images/ict-quiz/left-angle.png' />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow arrow-main arrow-next ${className}`} >
      <img src='../../images/ict-quiz/left-angle.png' />
    </div>
  );

}

const settings = {
  dots: false,
  infinite: false,
  autoplay:true,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  nav: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    }
  ],
};
const SelectedParticipant = () => {
  return (
    <Base>
      <div className="main-app pb-5">
        <div className='register-section-text text-center'>
          <h1 className="text-white py-3 py-md-5 m-0">City Round</h1>
        </div>
        <div className='selected-list-sec'>
          <div className='container'>
            <h2 className='page-title quicksand'>Selected Candidates</h2>
            <div className='selected-participant-carousel'>
              <Slider {...settings}>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
                  <div className="participant-card">
                    <Link to="/dashboard">
                        <Card.Body>
                          <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                          <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                        </Card.Body>
                    </Link>
                  </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className='container'>
          <h2 className='page-title quicksand text-white pt-5'>Other Candidates</h2>
          <div className='list-scroll'>
            <div className="participant-grid">
                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="participant-card">
                  <Link to="/dashboard">
                    <Card>
                      <Card.Body>
                        <Image src='../../images/ict-quiz/user.png' roundedCircle className="participant-img" />
                        <Card.Text className="participant-name quicksand mt-2">Prashansa Singhal</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};
export default SelectedParticipant;
