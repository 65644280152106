import React from 'react';

const ProgressCircle = ({ size, strokeWidth, percentage, strokeBgColor = "transparent", strokColor = "#FFF", text, subtext }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg width={size} height={size} className="circle-progress">
      <circle
        className="circle-background"
        stroke={strokeBgColor}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="circle-progress"
        stroke={strokColor}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: offset,
          transition: 'stroke-dashoffset 0.35s',
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
      {text &&
        <text x="50%" y="42%" textAnchor="middle" dy=".3em" fontSize="8px" fontWeight="bold" fill="#FFF">{text}</text>
      }
      {subtext &&
        <text x="50%" y="58%" textAnchor="middle" dy=".3em" fontSize="8px" fontWeight="bold" fill="#FFF">
          {subtext}
        </text>
      }
    </svg>
  );
};

export default ProgressCircle;
