import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../services/userService';

const initialState = {
  loading: false,
  error: null,
};

export const registerUser = createAsyncThunk(
  'student/registerUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userService.createUser(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const updateKid = createAsyncThunk(
  'student/updateKid',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userService.editKid(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const enrollToEvent = createAsyncThunk(
  'student/enrollToEvent',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userService.registerToEvent(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateKid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateKid.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateKid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(enrollToEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(enrollToEvent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(enrollToEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { clearError } = userSlice.actions;
export default userSlice.reducer;
