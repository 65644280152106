import React from 'react';
import "./style.css"

const Footer = () => {

  return (
    <footer className='footer'>
      <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <img src='../../images/classmate_logo.png'  className='ftr-logo' />
              <div className='ftr-location my-3'>
                <img src='../../images/location.svg'/>
                <span>Locate us on Google</span>
              </div>
              <div>
                <div className='d-flex flex-reverse flex-md-wrap app-logo'>
                  <span><img src='../../images/g-play.png' /></span>
                  <span className='mx-2 mx-md-0 ms-md-2'><img src='../../images/qr.png' /></span>
                  <span  className='mt-md-3 app-store-img'><img src='../../images/a-store.png' /></span>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <ul className='ftr-links'>
                <li><a href='#'>About Us</a></li>
                <li><a href='#'>Work with Us</a></li>
                <li><a href='#'>Contact Us</a></li>
                <li><a href='#'>Request Support</a></li>
                <li><a href='#'>Contribute Ideas</a></li>
                <li><a href='#'>Invite Others</a></li>
                <li><a href='#'>Share testimonial</a></li>
                <li><a href='#'>Request Refund</a></li>
              </ul>
            </div>
            <div className='col-md-5 d-flex flex-column'>
              <div className='ftr-newsletter order-2 order-md-1'>
                <h3>Subscribe to our Newsletter</h3>
                <div className='d-flex'>
                    <input type='text' className='form-control' placeholder='Email'/>
                    <button className='site-btn white-border-btn bg-transparent'>Subscribe </button>
                </div>
              </div>
              <div className='ftr-newsletter order-1 order-md-2'>
                <h3>Media Coverage</h3>
                <textarea rows={4} cols={20} />
              </div>
              <div className='ftr-newsletter order-3 order-md-3'>
                <h3>Follow us on</h3>
                <ul className='ftr-social-media'>
                    <li><a href='#'><img src='../../images/fb.png' /></a></li>
                    <li><a href='#'><img src='../../images/insta.png' /></a></li>
                    <li><a href='#'><img src='../../images/x.png' /></a></li>
                    <li><a href='#'><img src='../../images/linkedin.png' /></a></li>
                    <li><a href='#'><img src='../../images/youtube.png' /></a></li>
                </ul>
              </div>
            </div>
          </div>

      </div>
    </footer>
  );
};

export default Footer;
