import React from 'react'
import { Tooltip } from 'react-tooltip';
// import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { BASEPATH } from '../../../../services/config';

function PrevArrow(props) {

  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow arrow-main arrow-prev ${className}`} >
      {/* <ChevronLeft className="arrows" style={{ color: "#9E54E8" }} /> */}
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow arrow-main arrow-next ${className}`} >
      {/* <ChevronRight className="arrows" style={{ color: "#9E54E8" }} /> */}
    </div>
  );

}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  nav: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }
  ],
};

const Question = ({ data, setAnswer }) => {

  const quizLocalData = useSelector(state => state.quiz.localData);
  return (
    <div className="">
      <Tooltip id={`question-hint`} place="top" />
      <div className="help-alert-bx-shadow">
        <div className="help-alert-bx">
          <div className="help-txt pe-2 pe-md-5">
            <img src={`${BASEPATH}/images/mdi_stars_white.svg`} className="pe-1 pe-md-3" />
            {data?.current_question_number}.<span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />{<div dangerouslySetInnerHTML={{ __html: data?.question_text }} />}
          </div>
          {data?.hint &&
            <div
              className='highlight-txt ps-2 ps-md-5 cursor-pointer'
              data-tooltip-id={`question-hint`}
              data-tooltip-content={data?.hint}>
              <img src={`${window.location.origin}/images/hint.svg`} width={30} />
              <div className="text-end text-yellow ff1">Hint</div>
            </div>
          }
        </div>
        {data?.image_urls || data?.video_urls ?
          <div className="quiz-question-slider">
            <div className="quiz-question-slider-inner">
              <Slider {...settings}>
                {data?.video_urls && data?.video_urls?.map((val, index) => {
                  return (
                    <div key={index}>
                      <div className="quiz-video-player">
                        <ReactPlayer
                          url={val}
                          controls={true}
                        />
                      </div>
                    </div>
                  );
                })
                }
                {data?.image_urls && data?.image_urls?.map((val, index) => {
                  return (
                    <div key={index}>
                      <img className="ques-slider-img" src={val} alt="loading..." />
                    </div>
                  );
                })
                }
              </Slider>
            </div>
          </div>
          : ''}
      </div>
      {(quizLocalData?.continue_button || quizLocalData?.show_complete_button) ?
        <div className="clear-txt cursor-pointer">Clear response</div>
        : <div className="clear-txt cursor-pointer" onClick={() => setAnswer([])}>Clear response</div>}

    </div>
  );
}

export default Question;
