import React from 'react';
import childImg from "../../../assets/images/childern.png"
import { Link } from 'react-router-dom';

export const Banner = () => {
  return (
    <>
      <div className="cm-banner montserrat">
        <div className="container text-center">
          <div className="logo-container">
            <img src="../../images/classmate_logo.png" alt="Classmate Logo" />
          </div>
          <h2>Classmate All Rounder Competition</h2>
          <div className="highlight-banner-txt">
            <h4>Unlock Your Potential with Fun Learning!</h4>
            <img src="../../images/text-tag.png" alt="Text Tag" />
            <div className="text-center  d-flex justify-content-center pt-3">
              <Link to="/signup" replace={true} className="site-btn white-btn border-w-2x me-3">Registration Open</Link>
              <button className="site-btn bg-transparent white-border-btn">Download Brochure</button>
            </div>
          </div>
          <div className='text-end pt-5 pt-md-0 banner-bottom-img'>
            <img src={childImg} alt='childImg' className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};
