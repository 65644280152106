import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Loading } from 'notiflix';
import { getBrowserInfo } from '../../helper';

const VideoRecorder = forwardRef(({
  proctoring_type,
  video_proctoring_type,
  images_count,
  video_duration,
  videos_count,
  quiz_duration,
  onAutoCaptured,
  onVideoCaptured
}, ref) => {

  const [recording, setRecording] = useState(false);
  // const [videoURL, setVideoURL] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const mediaRecorderRef = useRef(null);
  const previewRef = useRef(null);
  const chunksRef = useRef([]);
  const captureIntervalIdRef = useRef(null);
  const recordingTimeoutRef = useRef(null);
  const [browserInfo, setBrowserInfo] = useState({ name: '', version: '', code: '' });
  const [mainStream, setMainStream] = useState(null)

  useImperativeHandle(ref, () => ({
    stopRecording
  }));

  useEffect(() => {
    setBrowserInfo(getBrowserInfo());
  }, []);

  useEffect(() => {
    return () => {
      if (mainStream) {
        mainStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mainStream]);

  useEffect(() => {
    if (browserInfo.code) startRecording();

    return () => {
      if (captureIntervalIdRef.current) {
        clearInterval(captureIntervalIdRef.current);
      }
    };
  }, [browserInfo]);

  const startRecording = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 640 },
          height: { ideal: 480 },
          frameRate: { ideal: 10, max: 15 }
        },
        audio: true,
      });
      previewRef.current.srcObject = mediaStream;
      setMainStream(mediaStream)

      let options = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 1000000
      }

      if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        options = { ...options, mimeType: 'video/webm; codecs=vp9' };
      } else if (MediaRecorder.isTypeSupported('video/webm')) {
        options = { ...options, mimeType: 'video/webm' };
      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        options = { ...options, mimeType: 'video/mp4' };
      } else {
        setError("No suitable mimeType found for this device");
      }

      mediaRecorderRef.current = new MediaRecorder(mediaStream, options);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunksRef.current, { type: options?.mimeType });
        chunksRef.current = [];
        // const videoURL = URL.createObjectURL(blob);
        // setVideoURL(videoURL);
        mediaStream.getTracks().forEach((track) => track.stop());
        if (onVideoCaptured && (proctoring_type === 'VIDEO' || proctoring_type === 'BOTH')) {
          // const videoZip = new JSZip();
          // const fileName = browserInfo?.code === 'SF' ? 'video.mp4' : 'video.webm'
          // videoZip.file(fileName, blob);
          // const videoZipBlob = await videoZip.generateAsync({ type: 'blob' });
          // onVideoCaptured(videoZipBlob); // Pass zip to parent component
          // downloadVideoFiles(blob);
          onVideoCaptured(blob);
        }
      };

      mediaRecorderRef.current.start();
      // setRecording(true);

      if (proctoring_type === 'VIDEO' || proctoring_type === 'BOTH') {
        if (video_proctoring_type === 'FULL') {
          setTimeout(stopRecording, quiz_duration * 1000);
        } else if (video_proctoring_type === 'PARTIAL') {
          managePartialVideoRecording();
        }
      }

      if (proctoring_type === 'PHOTO' || proctoring_type === 'BOTH') {
        const totalTimeInMs = quiz_duration * 1000;
        const interval = totalTimeInMs / images_count;
        captureIntervalIdRef.current = setInterval(captureImage, interval);
      }
    } catch (error) {
      console.error('Error accessing media devices.', error);
      setError('Error accessing media devices.');
    }
  };

  const stopRecording = async () => {
    // if (!recording) {
    //   console.warn('Recording is not currently running.');
    //   return;
    // }
    if (captureIntervalIdRef.current)
      clearInterval(captureIntervalIdRef.current);

    mediaRecorderRef.current.stop();
    if (previewRef.current.srcObject) {
      previewRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    // setRecording(false);
    if (onAutoCaptured && images_count > 0 && (proctoring_type === 'PHOTO' || proctoring_type === 'BOTH')) {
      Loading.standard();
      const imgZip = new JSZip();
      images.forEach((image, index) => {
        imgZip.file(`image_${index + 1}.png`, image.blob);
      });
      const imgZipBlob = await imgZip.generateAsync({ type: 'blob' });
      onAutoCaptured(imgZipBlob);
      Loading.remove();
      // saveAs(imgZipBlob, 'images.zip');
      // downloadImageFiles();
      // onAutoCaptured(images);
    }
  };

  const captureImage = () => {
    const videoElement = previewRef.current;
    if (videoElement) {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        setImages((prevImages) => {
          if (prevImages.length + 1 === images_count) {
            clearInterval(captureIntervalIdRef.current);
          }
          return [...prevImages, { blob }]
        });
      });
    }

  };

  const managePartialVideoRecording = () => {

    const partDuration = quiz_duration / videos_count;
    for (let i = 0; i < videos_count; i++) {
      const startTime = i * partDuration * 1000;
      setTimeout(() => {
        if (mediaRecorderRef.current?.state != "inactive")
          mediaRecorderRef.current.resume();
        setTimeout(() => {
          if (mediaRecorderRef.current?.state != "inactive")
            mediaRecorderRef.current.pause();
        }, video_duration * 1000);
      }, startTime);
    }
  };

  const downloadImageFiles = async () => {
    const imgZip = new JSZip();
    const imgFolder = imgZip.folder('images');
    images.forEach((image, index) => {
      imgFolder.file(`image_${index + 1}.png`, image.blob);
    });
    const imgZipBlob = await imgZip.generateAsync({ type: 'blob' });
    saveAs(imgZipBlob, 'images.zip');
  };

  const downloadVideoFiles = async (videoBlob) => {
    const videoZip = new JSZip();
    const fileName = browserInfo?.code === 'SF' ? 'video.mp4' : 'video.webm';
    videoZip.file(fileName, videoBlob);
    const videoZipBlob = await videoZip.generateAsync({ type: 'blob' });
    saveAs(videoZipBlob, 'video.zip');
  };

  return (
    <div>
      <video ref={previewRef} width="100%" height="100%" autoPlay muted playsInline></video>
    </div>
  );
});

export default VideoRecorder;
