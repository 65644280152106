const dev = {
  APIURL: "https://stagingapi.kid-ex.com/",
  TENANT_KEY: "965za52d-86f4-4794-93c4-d9bd2b386d84",
  MEDIAPATH: "https://kid-ex.com",
}
const prod = {
  APIURL: window?._env_?.API_URL,
  TENANT_KEY: window?._env_?.TENANT_KEY,
  MEDIAPATH: "https://kid-ex.com",
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;
const configData = { config }

export default configData;