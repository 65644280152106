import step1 from "../../assets/icons/step1.svg"
import step2 from "../../assets/icons/step2.svg"
import step3 from "../../assets/icons/step3.svg"
import step4 from "../../assets/icons/step4.svg"

export const registerSteps = [
  {
    sr: "1",
    icon: step1,
    title: "Who Can Register Here",
    items: [
      "This page is for participants registering independently.",
      "Note : Students from registered ABC partner schools should not register here—doing so will lead to disqualification."
    ]
  },
  {
    sr: "2",
    icon: step2,
    title: "Requirements",
    items: [
      "You’ll need a valid email address and mobile number to complete registration.",
      "We recommend using a parent’s email and mobile number for communication."
    ]
  },
  {
    sr: "3",
    icon: step3,
    title: "How to Register",
    items: [
      "Click Get Started below.",
      "Enter your mobile number when prompted, and verify it with the OTP sent to you.",
      "Once verified, select Register New Participant."
    ]
  },
  {
    sr: "4",
    icon: step4,
    title: "Taking the Quiz",
    items: [
      "After completing registration, you’ll be guided to the quiz.",
      "This is an AI-proctored quiz requiring access to your device’s webcam and microphone.",
      "This ensures a fair and secure assessment environment.",
    ]
  }
]