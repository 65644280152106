import React from "react";
export const StepOneContent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7 mb-5">
          <div className="border-bg-card">
            <img src="../images/register-nrc/img.png" alt="" className="w-100" />
            <h2 className="nunito mt-3 mb-2 mt-md-5 mb-md-4">Details</h2>
            <ul className="circle-ul-list montserrat">
              <li>The child is required to draw a scribble art on a piece of paper without lifting the pencil. </li>
              <li>The paper can be of any
                size between A5 to A4.</li>
              <li>Scribble art should cover at least 60% of the page, let the child colour the shapes
                created because of the scribble. </li>
              <li>The child can use 3 colours & shapes have to be
                coloured in a manner such that no 2 adjoining shapes have the same colour.</li>
              <li>The child is required to draw a scribble art on a piece of paper without lifting the pencil. </li>
              <li>The paper can be of any
                size between A5 to A4.</li>
              <li>Scribble art should cover at least 60% of the page, let the child colour the shapes
                created because of the scribble. </li>
              <li>The child can use 3 colours & shapes have to be
                coloured in a manner such that no 2 adjoining shapes have the same colour.</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="sticky mb-4">
            <div className="border-bg-card">
              <h2 className="montserrat mb-3 mb-md-4">Grading</h2>
              <h2 className="montserrat mb-3 mb-md-4 color-yellow">EX = Expert (150%)</h2>
              <p className="montserrat">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
              <div className="img-group">
                <div><img src="../images/register-nrc/group1.png" /></div>
                <div><img src="../images/register-nrc/group2.png" /></div>
                <div><img src="../images/register-nrc/group3.png" /></div>
                <div><img src="../images/register-nrc/group4.png" /></div>
                <div><img src="../images/register-nrc/group5.png" /></div>
              </div>
            </div>
            <div className="d-flex justify-content-end my-4">
              <a className="site-btn white-btn montserrat px-5" href="#">Next</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
