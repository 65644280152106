import React from 'react'

export const Eligibility = () => {
  return (
    <>
      <div className='eligibility-sec'>
        <div className='container montserrat'>
            <div className='flex-items-row justify-content-between'>
                  <h2>Eligibility Criteria </h2>
                  <p>
                    All school-going children aged <span>5-17</span> are welcome to <br /> participate in the Classmate All Rounder Competition.
                  </p>
            </div>
        </div>  
      </div>
    </>
  )
}
