import React, { useState } from "react";
import "./style.css";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import PopUp from "../../../components/PopUp/index";
export const SubmitActivityContent = () => {

   function PrevArrow(props) {

      const { className, style, onClick } = props;
      return (
        <div onClick={onClick} className={`arrow arrow-main arrow-prev ${className}`} >
          <img src='../../images/ict-quiz/leading-icon.png' />
        </div>
      );
    }
    
    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div onClick={onClick} className={`arrow arrow-main arrow-next ${className}`} >
          <img src='../../images/ict-quiz/leading-icon.png' />
        </div>
      );
    
    }
    
    const activitySlide = {
      dots: false,
      infinite: false,
      autoplay:true,
      speed: 500,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      nav: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }
      ],
    };
    const [show,setShow] = useState(false)
    const [showReview,setShowReview] = useState(false)
  return (
   <>
    <div className="container">
      <h2 className="activity-title nunito"><span>Activity :</span> Artificial Intelligence </h2>
      <div className="d-flex justify-content-between nunito  my-2 my-xl-5 py-2 py-xl-5">
         <div className="user-activity-detail">
            <div className="user-activity-avatar">
               <img src="../images/girl.svg" />
            </div>
            <div className="user-activity-content">
               <div className="d-flex align-items-center">
                  <div className="user-phone">+91 8448275914</div>
                  <div className="user-detail-delete review-request px-1 px-md-3"  onClick={() => setShowReview(true)}>
                     <img src="../images/register-nrc/review-request.png" />
                     <div className="trash-hover">Raise a Review Request</div>
                  </div>
               </div>
               <div className="time-ago quicksand">50 mins ago</div>
               <div className="not-happy-txt quicksand">Not happy with your entry? <a href="#">Resubmit</a></div>
            </div>
         </div>
         <div className="user-awaited quicksand indermediate">I (Indermediate)</div>
      </div>
      <div>
         
        <div className="activity-user-data-slider mb-5 pb-0 pb-xl-5">
          <Slider {...activitySlide}>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <img src="../images/register-nrc/slide-file.png" />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <ReactPlayer
                  url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
                  controls={true}
                  height={"300px"}
                  width={"100%"}
               />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <img src="../images/register-nrc/slide-file.png" />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
    <div className="page-divider"></div>
    <div className="container pb-5">
      <div className="d-flex justify-content-between nunito  my-2 my-xl-5 py-0 py-xl-5">
         <div className="user-activity-detail">
            <div className="user-activity-avatar">
               <img src="../images/girl.svg" />
            </div>
            <div className="user-activity-content">
               <div className="d-flex align-items-center">
                  <div className="user-phone">+91 8448275914</div>
                  <div className="user-detail-delete px-1 px-md-3" onClick={() => setShow(true)}>
                     <img src="../images/register-nrc/trash.png" />
                     <div className="trash-hover">Delete</div>
                  </div>
               </div>
               <div className="time-ago quicksand">50 mins ago</div>
               <div className="not-happy-txt quicksand">Not happy with your entry? <a href="#">Resubmit</a></div>
            </div>
         </div>
         <div className="user-awaited quicksand awaited">Grade Awaited</div>
      </div>
      <div>
         
        <div className="activity-user-data-slider mb-5 pb-0 pb-xl-5">
          <Slider {...activitySlide}>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <img src="../images/register-nrc/img2.png" />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <img src="../images/register-nrc/img3.png" />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
            <div className="activity-slide-data">
               <div className="activity-type-slide">
                  <img src="../images/register-nrc/img2.png" />
               </div>
               <div className="activity-type-name quicksand p-3 pb-4">Group 11009947.pdf</div>
            </div>
          </Slider>
        </div>
        <div className="remark nunito">
            <b>Assessor’s Remark</b>
            <p>Prompent or helped by someone else</p>
        </div>
      </div>
    </div>
      <PopUp size="lg" show={show} handleClose={() => setShow(false)}>
        <div className="register-nrc-modal">
            <div className="modal-header">
              <h2 className="register-nrc-modal-title montserrat text-start mb-0">Confirm Delete</h2>
            </div>
            <div className="register-nrc-modal-body p-5">
              <p className="montserrat modal-para disable-line-break">Are you sure you want to delete the submission? <br />
              Submission can be deleted within 30 mins only</p>
            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="site-btn blue-theme-border-btn montserrat  px-5">Cancel</button>
                <button type="button" className="site-btn blue-theme-btn montserrat  px-5">Delete</button>
            </div>
        </div>
      </PopUp> 
      <PopUp size="lg" show={showReview} handleClose={() => setShowReview(false)}>
        <div className="register-nrc-modal">
            <div className="modal-header">
              <h2 className="register-nrc-modal-title montserrat text-start mb-0">Confirm Review</h2>
            </div>
            <div className="register-nrc-modal-body p-5">
              <p className="montserrat modal-para">Are you sure you want to request a Review?</p>
            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="site-btn blue-theme-border-btn montserrat  px-5">Cancel</button>
                <button type="button" className="site-btn blue-theme-btn montserrat  px-5">Confirm</button>
            </div>
        </div>
      </PopUp> 

    </>
  );
};
