import React from 'react'
import { Link } from 'react-router-dom'

export const Stages = () => {
  return (
    <div className='container montserrat'>
      <div className='flex-items stage-content'>
        <div className='flex-items-row'>
          <div className='stage-circle'>
            <div>
              <div className='stage-icon'>
                <img src='../../images/sti1.png' />
                <span className='round-txt'>round 1</span>
              </div>
              <h5>School Round</h5>
            </div>
          </div>

          <div className='py-3 d-flex justify-content-between flex-1 '>
            <div className='stage-content-desc'>
              <p>
                An online, proctored IQ quiz based on the six <span>NEP development pillars.</span> <br />
                Each participant receives a personalized Holistic Development Card, offering feedback and guidance for growth.
              </p>
              <Link to="/signup" replace={true} className='site-btn white-btn border-w-2x'>Register Now</Link>
            </div>
            <img src='../../images/st1.png' className='stage-content-img' />
          </div>
        </div>
        <div className='flex-items-row'>
          <div className='stage-circle'>
            <div>
              <div className='stage-icon'>
                <img src='../../images/sti2.png' />
                <span className='round-txt'>round 2</span>
              </div>
              <h5>City<br /> Round</h5>
            </div>
          </div>

          <div className='py-3 d-flex justify-content-between flex-1 '>
            <div className='stage-content-desc'>
              <p>
                Top three students from each age group and school advance to the City Round.
              </p>
            </div>
            <img src='../../images/st2.png' className='stage-content-img' />
          </div>
        </div>
        <div className='flex-items-row'>
          <div className='stage-circle'>
            <div>
              <div className='stage-icon'>
                <img src='../../images/sti1.png' />
                <span className='round-txt'>round 3</span>
              </div>
              <h5>National Round</h5>
            </div>
          </div>

          <div className='py-3 d-flex justify-content-between justify-content-between flex-1 '>
            <div className='stage-content-desc'>
              <p>
                City-level winners across age groups converge in Bangalore for a three-day event featuring learning activities, challenges, and a quest for the All Rounder title.
              </p>
            </div>
            <img src='../../images/st3.png' className='stage-content-img' />
          </div>
        </div>
      </div>
    </div>
  )
}
