import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cloudService from '../services/cloudService';

const initialState = {
  loading: false,
  error: null,
};

export const createPresignedURL = createAsyncThunk(
  'cloud/createPresignedURL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await cloudService.generatePresignedURL(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const CloudFileUpload = createAsyncThunk(
  'cloud/CloudFileUpload',
  async ({ url, file, type = "image" }, { rejectWithValue }) => {
    try {
      let uploadFile = file;
      if (type === 'base64') {
        const base64ToBlob = (base64Data) => {
          const byteString = atob(base64Data.split(',')[1]);
          const mimeType = base64Data.match(/:(.*?);/)[1];
          const arrayBuffer = new Uint8Array(byteString.length);

          for (let i = 0; i < byteString.length; i++) {
            arrayBuffer[i] = byteString.charCodeAt(i);
          }

          return new Blob([arrayBuffer], { type: mimeType });
        };

        uploadFile = base64ToBlob(file);
      }

      var headers = new Headers();
      switch (type) {
        case 'base64':
          headers.append('Content-Type', uploadFile.type || 'image/png');
          break;
        case 'image':
          headers.append("Content-Type", "image/png");
          break;
        case 'zip':
          headers.append("Content-Type", "application/zip");
          break;
        default:
          headers.append("Content-Type", "image/png");
      }

      var options = { method: "PUT", body: uploadFile, headers, redirect: "follow" };
      const response = await fetch(`${url}`, options)
      return response;

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cloudSlice = createSlice({
  name: 'cloud',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPresignedURL.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPresignedURL.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPresignedURL.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default cloudSlice.reducer;
