import React from "react";
import ReactPlayer from "react-player";
export const StepTwoContent = () => {
  return (
    <div className="container step-two-content">
      <div className="row">
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 pe-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
        <div className="col-md-6 mb-5 ps-md-5">
          <div className="videoRadius">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
              controls={true}
              height={"340px"}
              width={"100%"}
            />
            <h2 className="montserrat mt-4  mb-2">Activity Description (English)</h2>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end pb-5">
        <a className="site-btn white-btn montserrat px-5" href="#">Next</a>
      </div>
    </div>
  );
};
