
import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import './style.css';
import Header from './Header/Header';
import Base from '../../components/Layout/Base';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentEventCode, MEDIAPATH } from '../../services/config';
import { isString } from '../../helper';
import { fetchCityList, fetchEventByName } from '../../features/sharedSlice';
import { resetQuiz, resetQuizData } from '../../features/quizSlice';

const Participant = () => {

  const dispatch = useDispatch();
  const { eventDetails } = useSelector(state => state.shared);

  useEffect(() => {
    dispatch(fetchEventByName({ eventCode: currentEventCode }));
    dispatch(fetchCityList());

    return () => {
      // dispatch(resetQuiz());
      dispatch(resetQuizData());
    }
  }, [])

  return (
    <Base>
      <div className="main-app">
        <div className='register-section-text text-center'>
          <h1 className="text-white">School Round</h1>
          <div className='description quicksand text-start'>
            <div className='container'>
              <p>
                Register new participant / Select participant to proceed with the School Round
              </p>
            </div>
          </div>
        </div>
        <Link to="/register">
          <div className='uploader quicksand'>
            <div className='uploader-circle'><img src="./images/ict-quiz/plus-y.png" /></div>
            <h2>Register New Participant</h2>
          </div>
        </Link>
        <div className='d-flex justify-content-center pb-5'>
          <div className="participant-grid container">
            {eventDetails?.kids?.map((kid) => (
              <div className="participant-card" key={kid?.kidId}>
                <Link to="/dashboard" state={{ kidId: kid?.kidId }}>
                  <Card>
                    <Card.Body>
                      <Image src={isString(kid?.kid_profile_url) ? kid?.kid_profile_url : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} roundedCircle className="participant-img" />
                      <Card.Text className="participant-name quicksand mt-2">{kid.name}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Base>
  );
};
export default Participant;
