import React from 'react'
import homeImg from "../../../assets/images/home.png";
import peopleImg from "../../../assets/images/people.png";
import tresImg from "../../../assets/images/tres.png";
import mapImg from "../../../assets/images/map.png";

export const Impact = () => {
  return (
    <>
       <div className="impact-section">
        <div className="container">
            <div className="row justify-content-center">
                 <div className="col-6 col-md-3 mb-4">
                 <div class="tr"></div>

                    <div className="house-card house-border-4 red-box">
                        <div className="house-card-number">3000+</div>
                        <div className="house-card-description">Schools Engaged</div>
                        <div className="house-card-icon"> 
                            <img className='' src={homeImg} alt="My Image" />
                        </div>
                    </div>
                </div>
                 <div className="col-6 col-md-3 mb-4">
                    <div class="box-4"></div>
                    <div className="house-card house-border-4">
                        <div className="house-card-number text-white">200,000+</div>
                        <div className="house-card-description">Participants</div>
                        <div className="house-card-icon">
                            <img className='' src={peopleImg} alt="My Image" />
                        </div>
                    </div>
                </div>
                 <div className="col-6 col-md-3 mb-4">
                    <div class="tr"></div>
                    <div className="house-card house-border-4 red-box">
                        <div className="house-card-number">13</div>
                        <div className="house-card-description">Cities Nationwide</div>
                        <div className="house-card-icon">
                            <img className='' src={tresImg} alt="My Image" />
                        </div>
                    </div>
                </div>
                 <div className="col-6 col-md-3 mb-4"> 
                    <div class="box-4"></div>
                    <div className="house-card house-border-4">
                        <div className="house-card-number text-white">5+</div>
                        <div className="house-card-description">States</div>
                        <div className="house-card-icon"> 
                            <img className='' src={mapImg} alt="My Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
